<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <nav_bar />
      <div
        style="width: 100%; height: 50px"
        class="d-md-block d-lg-block d-xl-none"
      ></div>
      <b-container fluid>
        <b-row style="margin-top: 9%">
          <b-col cols="6">
            <div class="buttons">
              <b-button @click="$router.go(-1)">
                <img src="../../../public/left-arrow.svg" alt="" />
              </b-button>
              <b-button>
                <img src="../../../public/down-arrow.png" alt="" />
              </b-button>
            </div>
          </b-col>
          <b-col cols="6">

          </b-col>
        </b-row>
<br>
        <b-row style="">
          <b-col cols="3">
            Filter KAP : <br>
            <select name="kap" id="kap" style="width: 100%">
              <option v-on:click="filterKAP('semua')">Semua</option>
              <option v-bind:key="kapx" v-for="kapx in this.kaps" v-on:click="filterKAP(kapx)">{{ kapx }}</option>
            </select>
          </b-col>
          <b-col cols="3">
            Filter Klien : <br>
            <select name="kap" id="kap" style="width: 100%">
            <option v-on:click="filterClient('semua')">Semua</option>
              <option v-bind:key="kapx" v-for="kapx in this.clients" v-on:click="filterClient(kapx)">{{ kapx }}</option>
            </select>
          </b-col>
                    <b-col cols="3">
            Filter Auditor : <br>
            <select name="kap" id="kap" style="width: 100%">
            <option v-on:click="filterAuditors('semua')">Semua</option>
              <option v-bind:key="kapx" v-for="kapx in this.auditor" v-on:click="filterAuditors(kapx)">{{ kapx }}</option>
            </select>
          </b-col>
                    <b-col cols="3">
                      Filter Project : <br>



                <b-form-input
                  id="filter-input"
                  v-model="filters"
                  type="search"
                  placeholder="Cari Project"
                  v-on:keyup="applyFilter()"
                ></b-form-input>

          </b-col>
        </b-row>
        
        <b-row style="margin-top: 2%">
          <b-col cols="12">
            <b-tabs v-model="showing" fill content-class="mt-3">
              <b-tab @click="showing = 0" title="Just Started"></b-tab>
              <b-tab @click="showing = 1" title="Mid Process"></b-tab>
              <b-tab @click="showing = 2" title="Almost Deadline"></b-tab>
              <b-tab @click="showing = 3" title="Over Deadline"></b-tab>
              <b-tab @click="showing = 4" title="Cancelled"></b-tab>
              <b-tab @click="showing = 5" title="Finished"></b-tab>
              <b-tab @click="showing = 6" title="All Projects"></b-tab>
            </b-tabs>
          </b-col>
        </b-row>

        <b-row style="margin-top: 2%">
          <b-col>
            <b-table
              hover
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"

              stacked="md"
              show-empty
              @filtered="onFiltered"
            >
              <template #cell(namaProject)="data">
                <a
                  style="cursor: pointer; color: #327ff2; font-weight: 600"
                  @click="go(data)"
                  >{{ data.value }}</a
                >
              </template>
              <template #cell(namaClient)="data">
                <a
                  style="cursor: pointer; color: #327ff2; font-weight: 600"
                  @click="client(data.item.clientId)"
                  >{{ data.value }}</a
                >
              </template>
              <template #cell(namaKAP)="data">
                <a
                  style="cursor: pointer; color: #327ff2; font-weight: 600"
                  @click="kap(data.item.KAPId)"
                  >{{ data.value }}</a
                >
              </template>

              <!--    <template #cell(actions)="row">
            <b-button
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
            >
              Info modal
            </b-button>
            <b-button size="sm" @click="row.toggleDetails">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template> -->
            </b-table>
          </b-col>
        </b-row>

        <b-row v-if="items.length > 5">
          <b-col>
            <div
              class="pagination"
              style="display: flex; justify-content: center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="d-sm-block d-md-none d-lg-none d-xl-none">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12">
            <div
              style="
                width: 100%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h4 class="text-center">
                <strong>Does not support mobile version</strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Nav_bar from "../nav_bar.vue";
import axios from "@/ip_config";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import vSelect from 'vue-select';
export default {
  components: { Nav_bar, Multiselect,vSelect},
  data() {
    return {
      filters: "",
      items: [],
      itemsNoFilter: [],
      fields: [
        { key: "nomor", label: "No" },
        { key: "namaProject", label: "Nama Project" },
        { key: "tahunProject", label: "Tahun Buku" },
        { key: "namaClient", label: "Nama Klien" },
        { key: "namaKAP", label: "Nama KAP" },
        { key: "progressProject", label: "Status" },
        { key: "persentaseTodoList", label: "Prosentase" },
        { key: "namaAuditor", label: "Auditor" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      showing: "",
      select: '',
      kaps: [],
      auditor: [],
      clients: [],
      filterKAPs:'semua',
      filterKlien:'semua',
      filterAuditor:'semua',

      
    };
  },
  computed: {
    ...mapState("apiProfileKAP", ["profileKAP", "role", "loadingK"]),
  },
  beforeMount() {
    this.showing = Number(this.$route.params.id);
    if (localStorage.getItem("idKAP")) {
      this.filters = this.profileKAP.namaKAP;
    }
  },
  mounted() {
    this.getData();
    localStorage.setItem("pembanding", "");
    localStorage.setItem("tahunProject", "");
  },
  watch: {
    showing: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.$router.replace("/detail_donut/" + this.showing);
        this.getData();
      }
    },
  },
  methods: {
    filterTable(row, filter) {
      console.log(row);
      // if (row.age >= filter) {
      //   return false;
      // } else {
      //   return true;
      // }
    },
    client(e) {
      localStorage.setItem("idClient", e);
      this.$store.dispatch("apiClient/getData");
      this.$router.push("/profile_client/" + e);
    },
    kap(id) {
      localStorage.setItem("idKAP", id);
      this.$router.push("/profile_kap/" + id);
    },
    go(e) {
      this.$router.push("/project/" + e.item.projectId);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addKAP(nama){
      var isExist = false;

      this.kaps.forEach((elm) => {
        if(elm == nama){
          isExist = true;
        }
      });

      if(!isExist){
        this.kaps.push(nama);
      }
    },
    addAuditor(nama){
      var isExist = false;

      this.auditor.forEach((elm) => {
        if(elm == nama){
          isExist = true;
        }
      });

      if(!isExist){
        this.auditor.push(nama);
      }
    },
    addClient(nama){
      var isExist = false;

      this.clients.forEach((elm) => {
        if(elm == nama){
          isExist = true;
        }
      });

      if(!isExist){
        this.clients.push(nama);
      }
    },

    filterKAP(kap){
      console.log("filter kap "+kap);
      this.filterKAPs = kap;
      this.applyFilter();
    },

    filterClient(klien){
      console.log("filter klien "+klien);
      this.filterKlien = klien;
      this.applyFilter();
    },

    filterAuditors(auditor){
      console.log("filter auditor "+auditor);
      this.filterAuditor = auditor;
      this.applyFilter();
    },

    applyFilter(){
      var result = [] ;

      this.itemsNoFilter.forEach((elm, idx) => {
        result.push(elm);
      });
      
      console.log("result"+result.toString);

      if(this.filterKAPs != 'semua'){
        result = result.filter(elm => elm.namaKAP == this.filterKAPs)
      }

      if(this.filterKlien != 'semua'){
        result = result.filter(elm => elm.namaClient == this.filterKlien)
      }

      if(this.filterAuditor != 'semua'){
        result = result.filter(elm => elm.namaAuditor == this.filterAuditor)
      }

      if(this.filters != '' && !(this.filters === undefined)){
        result = result.filter(elm => elm.namaProject.indexOf(this.filters) > -1 )
      }

console.log("ketik "+this.filters);
      this.items = result;

      this.totalRows = this.items.length;
      this.currentPage = 1;
    },
    getData() {
      let vm = this;

      axios
        .get("project/allProjectByUsersLogin", {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res "+JSON.stringify(res, null, 2));
          res.data.data.forEach((el) => {
            el.persentaseTodoList = el.persentaseTodoList + "%";
            if (el.progressProject == "Done") {
              el.progressProject = "100%";
            }
            if (el.progressProject == "just Started") {
              el.progressProject = "0%";
            }

            if (el.progressProject == "OVER") {
              el.progressProject = "Over Deadline";
            }

            if (vm.showing == 0) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "0%";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 1) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "50%";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              x.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 2) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "80%";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              x.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 3) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "Over Deadline";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              x.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 4) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "Cancelled";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              x.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 5) {
              let x = res.data.data.filter((elm) => {
                return elm.progressProject == "100%";
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              x.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items = x;
              vm.itemsNoFilter = vm.items;
            } else if (vm.showing == 6) {
              res.data.data.forEach((elm, idx) => {
                elm.nomor = idx + 1;
              });
              vm.items.forEach((elm, idx) => {
                this.addClient(elm.namaClient);
                this.addKAP(elm.namaKAP);
                this.addAuditor(elm.namaAuditor)
              });
              vm.items = res.data.data;
              vm.itemsNoFilter = vm.items;
            }

            console.log("kaps : "+this.kaps);
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.buttons {
  display: flex;
  width: 100%;
  .btn {
    width: 40px;
    height: 40px;
    margin-right: 3%;
    margin-bottom: 1%;
    background-color: transparent;
    border: 2px solid #327ff2;
    color: #327ff2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
</style>
